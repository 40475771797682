:root {
    --bglight: white;
    --bgdark: #252525;
    --theme1: #c90114;

    --gallery-index: 10000;
}

html,
body {
    font-family: 'Play', sans-serif;
    margin: 0;
    padding: 0;
}

.no-scroll {
    overflow: hidden;
}

.suppliers-swiper .swiper-wrapper {
    transition-timing-function: linear !important;
}

.mask-image {
    mask-image: linear-gradient(to bottom, black, transparent);
}
