@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-5xl;
    }
    h2 {
        @apply text-4xl;
    }
    h3 {
        @apply text-3xl;
    }
    h4 {
        @apply text-2xl;
    }
    h5 {
        @apply text-xl;
    }
    h6 {
        @apply text-lg;
    }
}

@layer components {
    .container-padding {
        @apply container mx-auto px-8;
    }

    .section-spacing {
        @apply py-16;
    }

    .nav-link {
        @apply hover:text-theme1 cursor-pointer duration-200 ease-in-out;
    }

    .theme-button1 {
        @apply border px-6 py-2 border-theme1 hover:bg-theme1 hover:text-white duration-200 ease-in-out;
    }

    .form-group {
        @apply flex flex-col;
    }

    input,
    textarea {
        @apply bg-transparent border-b p-1 outline-none shadow focus:border-b-theme1 duration-200;
    }

    ::-webkit-scrollbar {
        @apply w-3;
    }

    ::-webkit-scrollbar-track {
        @apply bg-bglight dark:bg-bgdark;
    }

    ::-webkit-scrollbar-thumb {
        @apply bg-theme1;
    }

    ::-webkit-scrollbar-thumb:hover {
        @apply bg-red-800;
    }
}
